
'use client';
import React from "react";
import Image from "next/image";
import { S3_BASE_URL } from "@/utils/constants";
import { CardSliderContent, ProductContent } from "@/types/product-content";

const products = [
  {
    title: "Mark",
    imageSrc: "/assets/images/new-image/set/mark.png",
    description: `<p class='mt-5 text-left product-description fw-regular'><strong class='text-white'>The Mark</strong>, a key element of the Set, is designed to treat a range of musculoskeletal challenges. 
                            From <strong class='text-white'> low back, hip, and SI joint pain </strong>to the nuances of <strong class='text-white'> leg length discrepancies and nerve pain</strong>, 
                            like sciatica, it offers targeted relief through manual therapy at home. Its unique design makes 
                            it the best hip flexor tool on the market, effectively reaching both the psoas and the deeper 
                            iliacus muscle with precision. <strong class='text-white'>Reclaim your body's natural balance with the Mark.</strong>
                        </p>`,
    tags: ["Back", "Hips", "Knees", "Feet", "Sciatica"],
    dotLineSrc: "/assets/images/new-image/set/dotLine.png",
  },
  {
    title: "Range",
    imageSrc: "/assets/images/new-image/set/range.png",
    description: `<p class='mt-5 text-left product-description fw-regular'>
                        <strong class='text-white'>The Range</strong>, another critical component of the Set, offers the benefits of having a 
                        physical therapist at home. Targeted pressure to your <strong class='text-white'>suboccipital, upper trapezius, and pec minor</strong> muscles effectively 
                        aligns your upper body, improves posture, and signals the brain to relax the body. The Range <strong class='text-white'>addresses neck pain, 
                        headaches, migraines, rounded shoulders, forward head posture, shoulder and rotator cuff pain, jaw pain, and stress</strong>, 
                        guiding you towards a relaxed and pain-free existence.
                    </p>`,
    tags: ["Neck", "Shoulders", "Jaw", "Headaches", "Migraine"],
    dotLineSrc: "/assets/images/new-image/set/dotLine.png",
  },
  {
    title: "Orbit",
    imageSrc: "/assets/images/new-image/set/orbit.png",
    description: `<p class='mt-5 text-left product-description fw-regular'>
                        An ideal warm-up and complement to the Mark, <strong class='text-white'>the Orbit</strong> uses broad pressure to <strong class='text-white'>release tension in the hip flexor, 
                        glutes, and external hip rotator muscles</strong>. To ease you into precise pressure, the Orbit provides a lighter approach 
                        that prepares you for the Mark while targeting opposing muscles on the backside of the pelvis. Compact and travel-ready, 
                        the Orbit is perfect for use anywhere—<strong class='text-white'>ensuring fast and efficient myofascial release on the go</strong>.
                    </p>
`,
    tags: ["Back", "Hips", "Knees", "Feet", "Sciatica"],
    dotLineSrc: "/assets/images/new-image/set/dotLine.png",
  },
  {
    title: "Band",
    imageSrc: "/assets/images/new-image/set/band.png",
    description: ` <p class='mt-5 text-left product-description fw-regular'>
                            After resolving tension and restoring alignment, <strong class='text-white'>the Band is essential to build strength in the muscles that stabilize your body's foundation</strong>. 
                            Including this step ensures lasting relief from chronic pain. The Band's distinct woven design and non-slip grip activates key muscle groups, 
                            <strong class='text-white'>elevating your workouts with a strong, stable, and aligned pelvis</strong>.
                        </p>`,
    tags: ["Back", "Hips", "Knees", "Feet", "Sciatica"],
    dotLineSrc: "/assets/images/new-image/set/dotLine.png",
  },
  {
    title: "The Book",
    imageSrc: "/assets/images/new-image/set/book.png",
    description: `<p class='mt-5 text-left product-description fw-regular'>
                            Dive into <strong class='text-white'> 'Tight Hip, Twisted Core'</strong> to embark on a journey of understanding and empowerment. 
                            A companion to Aletha's suite of products, this book <strong class='text-white'>offers deep insights into the science of 
                            pain and the body's incredible ability to heal</strong>. While our tools are designed for ease of use, 
                            this guide sheds light on the intricacies of musculoskeletal health—making it a valuable resource 
                            for those eager to delve into the world of holistic well-being.
                        </p>`,
    tags: ["Back", "Hips", "Knees", "Feet", "Sciatica"],
    dotLineSrc: "/assets/images/new-image/set/dotLine.png",
  },
];

interface  DescriptionProps {
  product: ProductContent;
}

export default function ProductDescription({ product }: DescriptionProps) {
  return (
    <>
      <div className="container mb-sm-5">
        {product?.card_slider_contents?.map((item: CardSliderContent, index: number) => {
          const taglines = item?.sub_title?.split(",")
            .map((tag: string) => tag.trim()); 

          return (
            <div
              key={index}
              className="product-section-holder d-flex flex-column align-items-center justify-content-center"
            >
              <p className="product-title text-white my-sm-5 mb-1">
                {item?.title || ""}
              </p>

              <div className="product-detail-holder">
                <Image
                  src="/assets/images/new-image/set/dotLine.png"
                  alt="Description of the image"
                  width={705}
                  height={94}
                  className="img-fluid ms-md-3 mt-3 mb-4"
                />

                <div className="d-flex flex-row justify-content-center gap-md-5 gap-2 mb-sm-5 mb-2">
                  {taglines?.map(
                    (
                      tag:
                        | string
                        | number
                        | bigint
                        | boolean
                        | React.ReactElement<
                            any,
                            string | React.JSXElementConstructor<any>
                          >
                        | Iterable<React.ReactNode>
                        | React.ReactPortal
                        | Promise<React.AwaitedReactNode>
                        | null
                        | undefined,
                      tagIndex: React.Key | null | undefined
                    ) => (
                      <p
                        key={tagIndex}
                        className="text-white px-sm-3 mb-0 px-2 tags"
                      >
                        {tag}
                      </p>
                    )
                  )}
                </div>

                <div className="product-description-sec">
                  <Image
                    src={`${S3_BASE_URL}${item?.image_url}`}
                    alt="Description of the image"
                    width={750}
                    height={422}
                    className="img-fluid"
                  />

                  <div className="mt-5 text-left ">
                    <div
                      className=""
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
