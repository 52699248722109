
"use client";

import React from "react";
import { Modal } from "react-bootstrap";

interface SliderModalProps {
  show: boolean;
  product: any;
  index:any;
  handleClose: () => void;
}

const SliderModal: React.FC<SliderModalProps> = ({
  show,
  handleClose,
  product,
  index
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered size="xl">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="container">
          <iframe
            width="100%"
            height="500"
            className="youtube-video "
            src={product?.video_url}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            title="Video"
          ></iframe>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SliderModal;
