"use client";

import React from "react";
import Image from "next/image";
import { S3_BASE_URL } from "@/utils/constants";
import { ProductContent } from "@/types/product-content";
interface FeatureProps {
  product: ProductContent;
}

export default function ProductFeatures({ product }: FeatureProps) {
  return (
    <>
      <div className="detail-container container mb-md-5">
        <div className="row">
          <div className="col-12 col-sm-6 d-flex flex-column justify-content-center order-2 order-md-1 detail-section">
            <div className="pe-md-5">
              <p className="text-white mb-4 detail-heading fw-bold ">
                {product?.features_title}
              </p>
              <h4 className="text-white mb-4 details-title">
                {product?.features_sub_title}
              </h4>
              <div className="description-details">
                <div
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: product?.features_description,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 order-1 order-md-2 img-section p-0">
            <Image
              src={`${S3_BASE_URL}${product?.features_desktop_image_url}`}
              alt="Description of the image"
              width={700}
              height={630}
              className="img-fluid d-md-block d-none"
            />

            <Image
              src={`${S3_BASE_URL}${product?.features_mobile_image_url}`}
              alt="Description of the image"
              width={700}
              height={630}
              className="img-fluid d-md-none d-block"
            />
          </div>
        </div>
      </div>
    </>
  );
}
