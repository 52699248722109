"use client";
import React from "react";
import Image from "next/image";
import { S3_BASE_URL } from "@/utils/constants";
import { ProductContent } from "@/types/product-content";

interface EfficiencyProps {
  product: ProductContent;
}

export default function ProductEfficiency({ product }: EfficiencyProps) {
  return (
    <>
      <div className="mt-2 range-sections-holder container d-none d-sm-block">
        {product?.card_slider_contents.length >= 1 ? (
          <div className="row">
            <div className="col-6">
              <Image
                src={`${S3_BASE_URL}${product?.card_slider_contents[0]?.image_url}`}
                alt="Description of the image"
                width={860}
                height={863}
                className="img-fluid"
              />
            </div>
            <div className="col-6 d-flex align-items-center justify-content-center">
              <div className="d-flex flex-column mt-5 pt-5">
                <div className="mx-auto">
                  <Image
                    src="/assets/images/new-image/range-icon-1.png"
                    alt="Description of the image"
                    width={135}
                    height={135}
                    className="img-fluid mt-2"
                  />
                </div>
                <p className="text-white text-center mt-3 range-product-title">
                  {product?.card_slider_contents[0]?.title}
                </p>
                <p className="text-white text-center mt-4 mx-auto w-50 range-product-description">
                  {product?.card_slider_contents[0]?.description}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {product?.card_slider_contents.length >= 2 ? (
          <div className="row">
            <div className="col-6">
              <Image
                src={`${S3_BASE_URL}${product?.card_slider_contents[1]?.image_url}`}
                alt="Description of the image"
                width={860}
                height={863}
                className="img-fluid"
              />
            </div>
            <div className="col-6 d-flex align-items-center justify-content-center">
              <div className="d-flex flex-column mt-5 pt-5">
                <div className="mx-auto">
                  <Image
                    src="/assets/images/new-image/range-icon-2.png"
                    alt="Description of the image"
                    width={135}
                    height={135}
                    className="img-fluid mt-2"
                  />
                </div>
                <p className="text-white text-center mt-3 range-product-title">
                  {product?.card_slider_contents[1]?.title}{" "}
                </p>
                <p className="text-white text-center mt-4 mx-auto w-50 range-product-description">
                  {product?.card_slider_contents[1]?.description}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {product?.card_slider_contents.length >= 3 ? (
          <div className="row">
            <div className="col-6">
              <Image
                src={`${S3_BASE_URL}${product?.card_slider_contents[2]?.image_url}`}
                alt="Description of the image"
                width={860}
                height={863}
                className="img-fluid"
              />
            </div>
            <div className="col-6 d-flex align-items-center justify-content-center">
              <div className="d-flex flex-column mt-5 pt-5">
                <div className="mx-auto">
                  <Image
                    src="/assets/images/new-image/range-icon-3.png"
                    alt="Description of the image"
                    width={135}
                    height={135}
                    className="img-fluid mt-2"
                  />
                </div>
                <p className="text-white text-center mt-3 range-product-title">
                  {product?.card_slider_contents[2]?.title}{" "}
                </p>
                <p className="text-white text-center mt-4 mx-auto w-50 range-product-description">
                  {product?.card_slider_contents[2]?.description}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="mt-2 container range-sections-holder d-block d-sm-none">
        {product?.card_slider_contents.length >= 1 ? (
          <div className="row">
            <div className="col-12">
              <div className="d-flex flex-column mt-5 pt-5">
                <div className="mx-auto">
                  <Image
                    src="/assets/images/new-image/range-icon-1.png"
                    alt="Description of the image"
                    width={56}
                    height={56}
                    className="img-fluid mt-2"
                  />
                </div>
                <p className="text-white text-center mt-3 range-product-title">
                  {product?.card_slider_contents[0]?.title}
                </p>
              </div>
              <Image
                src={`${S3_BASE_URL}${product?.card_slider_contents[0]?.image_url}`}
                alt="Description of the image"
                width={364}
                height={377}
                className="img-fluid"
              />
              <div className="text-white text-center mt-4 mx-4 range-product-description">
                {product?.card_slider_contents[0]?.description}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {product?.card_slider_contents.length >= 2 ? (
          <div className="row">
            <div className="col-12 position-relative">
              <div className="d-flex flex-column position-absolute top-0 end-0 mt-5 pt-5 me-4">
                <div className="mx-auto">
                  <Image
                    src="/assets/images/new-image/range-icon-2.png"
                    alt="Description of the image"
                    width={56}
                    height={56}
                    className="img-fluid mt-2"
                  />
                </div>
                <p className="text-white text-center mt-3 range-product-title">
                  {product?.card_slider_contents[1]?.title}
                </p>
              </div>
              <Image
                src={`${S3_BASE_URL}${product?.card_slider_contents[1]?.image_url}`}
                alt="Description of the image"
                width={364}
                height={377}
                className="img-fluid"
              />
              <div className="text-white text-center mt-4 mx-4 range-product-description">
                {product?.card_slider_contents[1]?.description}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {product?.card_slider_contents.length >= 3 ? (
          <div className="row">
            <div className="col-12 position-relative">
              <div className="d-flex flex-column position-absolute top-0 start-0 mt-3 pt-5 ms-4">
                <div className="mx-auto">
                  <Image
                    src="/assets/images/new-image/range-icon-3.png"
                    alt="Description of the image"
                    width={56}
                    height={56}
                    className="img-fluid mt-2"
                  />
                </div>
                <p className="text-white text-center mt-3 range-product-title  text-wrap">
                  {product?.card_slider_contents[2]?.title}
                </p>
              </div>
              <Image
                src={`${S3_BASE_URL}${product?.card_slider_contents[2]?.image_url}`}
                alt="Description of the image"
                width={364}
                height={377}
                className="img-fluid ms-5 ps-5"
              />
              <p className="text-white text-center mt-4 mx-4 range-product-description">
                {product?.card_slider_contents[2]?.description}
              </p>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
