"use client";
import React, { useEffect, useState } from "react";
import CartModal from "../popUpSection/cartModel";
import { useCart } from "@/context/CartContext";
import {
  addProductToCart,
  createCart,
  fetchCart,
  fetchProducts,
  updateCartLineQuantity,
} from "@/utils/Shopify/products";
import { ProductVariantMapping } from "@/types/shopify-product";

export default function StaticBottomAd({
  selectedvariant,
  productList,
  product,
  productPrice,
  slug,
  productIndex,
}: any) {
  const [cartShowModel, setCartShowModel] = useState<boolean>(false);
  const [shopifyProducts, setShopifyProducts] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const { itemCount, setItemCount } = useCart();
  const { cart, setCart } = useCart();
  const handleCartShow = () => {
    setCartShowModel(true);
  };
  const handleCartClose = () => {
    setCartShowModel(false);
  };
  useEffect(() => {
    const loadProducts = async () => {
      try {
        setLoading(true);
        const data = await fetchProducts();
        setShopifyProducts(data);
        data.forEach((shopifynode: any) => {
          if (shopifynode.node.id.includes(product.productId)) {
            product.video_banner_title = shopifynode.node.title;
            shopifynode.node.variants.edges.forEach((edge: any) => {
              if (product.variantIds.length > 1) {
                if (edge.node.id.includes(product.variantIds[1])) {
                  product.shopifyPrice = "$" + Number(edge.node.priceV2.amount);
                }
              } else if (product.variantIds.length === 1) {
                if (edge.node.id.includes(product.variantIds[0])) {
                  product.shopifyPrice = "$" + Number(edge.node.priceV2.amount);
                }
              }
            });
          }
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError("Failed to fetch products");
      }
    };
    loadProducts();
  }, []);

  const productStockList: ProductVariantMapping[] = [];
  productList.forEach(
    (product: {
      shopify_product_id: string;
      shopify_variant_id: any;
      name: string;
      variant_sub_title: string;
      color: any;
    }) => {
      const productId = product.shopify_product_id; // The part before the first hyphen
      const variantId = product.shopify_variant_id; // Use the full variant ID
      const title = product.name;
      const subtitle = product.variant_sub_title;
      const color = product.color;

      const existingProduct = productStockList.find(
        (mapping) => mapping.productId === productId
      );

      if (!existingProduct) {
        productStockList.push({
          productId: productId,
          variantId: [variantId],
          title: title,
          variant_sub_title: subtitle,
          color: color,
        });
      } else {
        existingProduct.variantId.push(variantId);
      }
    }
  );
  const handleAddToCart = async (productStockList: ProductVariantMapping[]) => {
    const productId = productStockList[0]?.productId;
    let variantId = "";

    if (selectedvariant === null) {
      variantId = productStockList[0]?.variantId[0];
    } else {
      variantId = selectedvariant;
    }

    // if (typeof window.fbq === 'function') {
    //   window.fbq('track', 'addToCart'
    //   );
    // }
    // window.dataLayer?.push({
    //   event: 'addToCart',
    //   CustomerId :'user',
    //   ecommerce: {
    //     currencyCode:'USD',
    //     add:{
    //       products:[
    //         {
    //           productId: productId,
    //           variantId: variantId,
    //           name: product.title||'',
    //           image: product.tutorial_desktop_image_url,
    //           price: product?.video_banner_price,
    //         }
    //       ]
    //     }
    //   },
    // });
    let storedData = localStorage.getItem("cartCheckoutData");
    let cartId = storedData ? JSON.parse(storedData).cartId : null;

    if (cartId) {
      // Fetch the existing cart data
      const cartData = await fetchCart(cartId);

      // Check if the product already exists in the cart
      let existingLineItem;
      if (variantId) {
        // If there's a variant, check if the variant already exists in the cart
        existingLineItem = cartData.lines.edges.find(
          (edge: any) => edge.node.merchandise.id === variantId
        );
      } else {
        // If no variant, find the product by productId
        existingLineItem = cartData.lines.edges.find(
          (edge: any) => edge.node.merchandise.product.id === productId
        );
      }

      let updatedCart;
      if (existingLineItem) {
        // If the product (variant or simple product) already exists in the cart, update the quantity
        updatedCart = await updateCartLineQuantity(
          cartId,
          existingLineItem.node.id,
          existingLineItem.node.quantity + 1
        );
        setCart(updatedCart);
      } else {
        // If the product (variant or simple product) is new, add it to the cart
        if (variantId) {
          // If there's a variant, add the product with variant
          updatedCart = await addProductToCart(cartId, variantId, 1);
        } else {
          // If it's a simple product, add it by productId
          updatedCart = await addProductToCart(cartId, productId, 1);
        }
        setCart(updatedCart);
      }

      // Recalculate and update the total item count based on the updated cart
      const newItemCount = updatedCart.lines.edges.reduce(
        (total: any, edge: any) => total + edge.node.quantity,
        0
      );
      setItemCount(newItemCount);

      // Update local storage with current cartId and checkoutId (if any)
      storedData = localStorage.getItem("cartCheckoutData");
      const { checkoutId } = storedData
        ? JSON.parse(storedData)
        : { checkoutId: null };
      localStorage.setItem(
        "cartCheckoutData",
        JSON.stringify({ cartId, checkoutId })
      );
    } else {
      // If no cart ID exists, create a new cart
      const newCartId = await createCart();
      const newCheckoutId = null;
      localStorage.setItem(
        "cartCheckoutData",
        JSON.stringify({ cartId: newCartId, checkoutId: newCheckoutId })
      );

      // Fetch the newly created cart
      const cartData = await fetchCart(newCartId);
      setCart(cartData);

      // Add the product to the newly created cart
      let updatedCart;
      if (variantId) {
        // If there's a variant, add the product with variant
        updatedCart = await addProductToCart(newCartId, variantId, 1);
      } else {
        // If it's a simple product, add it by productId
        updatedCart = await addProductToCart(newCartId, productId, 1);
      }
      setCart(updatedCart);

      // Recalculate and update the item count
      const newItemCount = updatedCart.lines.edges.reduce(
        (total: any, edge: any) => total + edge.node.quantity,
        0
      );
      setItemCount(newItemCount);
    }
  };

  return (
    <>
      <CartModal cartShow={cartShowModel} handleClose={handleCartClose} />
      <div className="common-section-ad d-none d-sm-block px-5">
        <div className="d-flex flex-row justify-content-end align-items-center px-5">
          <div className="">
            <p className="product-name text-white mb-0 text-center pe-5">
              {product?.video_banner_title || ""}
            </p>
          </div>
          <div className="d-flex flex-row align-items-center ps-5  border-start price-section">
            <p className="product-price text-white mb-0 me-5 text-center">
              {productPrice
                ? " $" + `${Number(productPrice)}`
                : product?.shopifyPrice || " "}
            </p>
            <button
              className="btn btn-primary text-white continue-btn"
              onClick={() => {
                handleCartShow();
                handleAddToCart(productStockList);

                // handleAddToCart(product.productId, product.variantIds);
              }}
            >
              Add to cart
            </button>
          </div>
        </div>
      </div>
      <div className="bottom-nav-bar  d-block d-sm-none">
        <div className="common-section-ad ">
          <div className="row d-flex justify-content-center align-items-center px-4 py-3">
            <div className="col-8">
              <div className="d-flex flex-row">
                <p className="product-name text-white mb-0 text-center me-5">
                  {product?.video_banner_title || ""}
                </p>
                <p className="product-price text-white mb-0 text-center">
                {productPrice
                    ? " $" + `${Number(productPrice)}`
                    : product?.shopifyPrice || " "}
                </p>
              </div>
            </div>
            <div className="col-4">
              <button
                className="btn btn-primary text-white continue-btn"
                onClick={() => {
                  handleCartShow();
                  handleAddToCart(productStockList);
                  // handleAddToCart(product.productId, product.variantIds);
                }}
              >
                Add to cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
