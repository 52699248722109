'use client';

import { ProductContent } from '@/types/product-content';
import React from 'react';
interface AdditionalContentProps{

  product:ProductContent
  
}


export default function ProductAdditionalContent({ product }: AdditionalContentProps) {
  return (
    <>
      <div className='text-center watch-vedio-top-section px-3 mb-5 my-sm-5'>
        <h2 className='text-white '>{product?.additional_title || ""}</h2>
        <h6 className='text-white w-75 w-sm-100 mx-auto'>{product?.additional_description || ""}</h6>
      </div>
    </>
  );
}
