"use client";
import React from "react";
import Image from "next/image";
import { S3_BASE_URL } from "@/utils/constants";
import { ProductContent } from "@/types/product-content";
interface BenefitProps {
  product: ProductContent;
}

export default function ProductBenefits({ product }: BenefitProps) {
  return (
    <>
      <div className="mark-product-wrapper d-none d-sm-block">
        <div className="Engineered-section d-flex flex-column justify-content-center">
          <p className="engineered-heading text-white my-5">
            {product?.second_card_title}
          </p>
          <div className="mx-auto">
            <Image
              src="/assets/images/new-image/90secCircle.png"
              alt="Description of the image"
              width={135}
              height={135}
              className="img-fluid mt-2"
            />
          </div>
          <p className="text-white text-center  mt-4 mb-0 sub-title">
            {" "}
            {product?.second_card_slider_contents[0]?.title}
          </p>
          <p className="text-white text-center mt-4 w-25 px-5 mx-auto description">
            {product?.second_card_slider_contents[0]?.description}
          </p>
        </div>
        <div className="mark-section-two">
          {product?.second_card_slider_contents?.length >= 1 ? (
            <Image
              src={`${S3_BASE_URL}${product?.second_card_slider_contents[0]?.image_url}`}
              alt="Description of the image"
              width={1440}
              height={653}
              className="img-fluid w-100"
            />
          ) : (
            <></>
          )}
        </div>
        <div className="mark-section-three mt-2">
          {product?.second_card_slider_contents?.length >= 2 ? (
            <div className="row">
              <div className="col-6">
                <Image
                  src={`${S3_BASE_URL}${product?.second_card_slider_contents[1]?.image_url}`}
                  alt="Description of the image"
                  width={860}
                  height={863}
                  className="img-fluid w-100"
                />
              </div>
              <div className="col-6 d-flex align-items-center justify-content-center">
                <div className="d-flex flex-column mt-5 pt-5">
                  <div className="mx-auto">
                    <Image
                      src="/assets/images/new-image/mark-2.png"
                      alt="Description of the image"
                      width={135}
                      height={135}
                      className="img-fluid mt-2"
                    />
                  </div>
                  <p className="text-white text-center mt-4 mb-0 sub-title w-50 mx-auto">
                    {" "}
                    {product?.second_card_slider_contents[1]?.title}
                  </p>
                  <p className="text-white text-center mt-4 mx-auto description w-75 mx-auto">
                    {product?.second_card_slider_contents[1]?.description}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="mark-section  mt-2">
          <div className="">
            {product?.second_card_slider_contents?.length >= 3 ? (
              <div className=" position-relative">
                <Image
                  src={`${S3_BASE_URL}${product?.second_card_slider_contents[2]?.image_url}`}
                  alt="Description of the image"
                  width={1440}
                  height={909}
                  className="img-fluid w-100"
                />

                <div className=" d-flex align-items-center justify-content-center position-absolute h-100 w-50 top-0">
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="mx-auto">
                      <Image
                        src="/assets/images/new-image/mark-3.png"
                        alt="Description of the image"
                        width={135}
                        height={135}
                        className="img-fluid mt-2"
                      />
                    </div>
                    <p className="text-white text-center mt-4 mb-0 sub-title w-50 mx-auto">
                      {" "}
                      {product?.second_card_slider_contents[2]?.title}
                    </p>
                    <p className="text-white text-center mt-4 description w-50 mx-auto">
                      {product?.second_card_slider_contents[2]?.description}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div className="mark-product-wrapper p-0 d-block d-sm-none">
        <div className="Engineered-section d-flex flex-column  justify-content-center">
          <p className="engineered-heading-mobile text-center text-white my-5">
            {product?.second_card_title}
          </p>
          <div className="mx-auto">
            <Image
              src="/assets/images/new-image/90secCircle.png"
              alt="Description of the image"
              width={56}
              height={56}
              className="img-fluid mt-2"
            />
          </div>
          {product?.second_card_slider_contents?.length >= 1 ? (
            <>
              <p className="text-white text-center  mt-4 mb-0 sub-title">
                {" "}
                {product?.second_card_slider_contents[0]?.title}
              </p>

              <Image
                src={`${S3_BASE_URL}${product?.second_card_slider_contents[0]?.image_url}`}
                alt="Description of the image"
                width={1440}
                height={653}
                className="img-fluid w-100"
              />

              <p className="text-white text-center mt-4 description mx-5">
                {product?.second_card_slider_contents[0]?.description}
              </p>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="mark-section-two my-5">
          <div className="position-relative w-100">
            {product?.second_card_slider_contents?.length >= 2 ? (
              <>
                <Image
                  src={`${S3_BASE_URL}${product?.second_card_slider_contents[1]?.image_url}`}
                  alt="Description of the image"
                  width={860}
                  height={863}
                  className="img-fluid w-75"
                />
              </>
            ) : (
              <></>
            )}
            <div className="d-flex align-items-center position-absolute  end-0 top-0">
              {product?.second_card_slider_contents?.length >= 2 ? (
                <>
                  <div className="d-flex flex-column mt-5 pt-3">
                    <div className="mx-auto">
                      <Image
                        src="/assets/images/new-image/mark-2.png"
                        alt="Description of the image"
                        width={56}
                        height={56}
                        className="img-fluid mt-2"
                      />
                    </div>

                    <div className="text-white text-center mt-2 sub-title d-flex flex-wrap w-75 mx-auto">
                      <div>
                        {product?.second_card_slider_contents[1]?.title}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            {product?.second_card_slider_contents?.length >= 2 ? (
              <p className="text-white text-center mt-4 mx-5 description">
                {product?.second_card_slider_contents[1]?.description}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="mark-section-three my-5">
          <div className="position-relative w-100">
          {product?.second_card_slider_contents?.length >= 3 ? (
            <Image
              src={`${S3_BASE_URL}${product?.second_card_slider_contents[2]?.image_url}`}
              alt="Description of the image"
              width={860}
              height={863}
              className="img-fluid "
            />
          ) : (
            <></>
          )}
            <div className="d-flex align-items-center position-absolute ps-2 ms-5 start-0 top-0">
            {product?.second_card_slider_contents?.length >= 3 ? (
              <div className="d-flex flex-column mt-5 pt-5">
                <div className="mx-auto">
                  <Image
                    src="/assets/images/new-image/mark-3.png"
                    alt="Description of the image"
                    width={56}
                    height={56}
                    className="img-fluid mt-2"
                  />
                </div>
                <p className="text-white text-center mt-2 sub-title">
                  {product?.second_card_slider_contents[2]?.title}
                </p>
              </div>
            ):<></>}
            </div>
            {product?.second_card_slider_contents?.length >= 3 ? (
            <p className="text-white text-center mt-4 mx-5 description">
              {product?.second_card_slider_contents[2]?.description}
            </p>
            ):<></>}
          </div>
        </div>
      </div>
    </>
  );
}
