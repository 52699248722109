"use client";

import React from "react";
import Image from "next/image";
import { S3_BASE_URL } from "@/utils/constants";
import { ProductContent } from "@/types/product-content";
interface WarmUpProps {
  product: ProductContent;
}
export default function ProductWarmUp({ product }: WarmUpProps) {
  return (
    <>
      <div className="detail-container container mb-md-5">
        <div className="row">
          <div className="col-12 col-sm-6 img-section">
            <Image
              src={`${S3_BASE_URL}${product?.warm_up_desktop_image_url}`}
              alt="Description of the image"
              width={700}
              height={630}
              className="img-fluid d-md-block d-none"
            />
            <Image
              src={`${S3_BASE_URL}${product?.warm_up_mobile_image_url}`}
              alt="Description of the image"
              width={700}
              height={630}
              className="img-fluid d-md-none d-block"
            />
          </div>
          <div className="col-12 col-sm-6 d-flex flex-column justify-content-center detail-section">
            <div className="pe-md-5">
              <p className="text-white mb-4 detail-heading fw-bold ">
                {product?.warm_up_title || ""}
              </p>
              <h4 className="text-white mb-4 details-title">
                {product?.warm_up_sub_title || ""}
              </h4>
              <p className="description-details">
                {product?.warm_up_description || ""}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
