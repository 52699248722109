import { useEffect } from 'react';

const HsaPrequalify: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://truemed-public.s3.us-west-1.amazonaws.com/truemed-ads/prequal-widget.min.js";
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      id="truemed-prequalify"
      style={{ fontSize: '16px', color: 'white' , marginTop: '7px', marginBottom: '10px' }}
      data-public-id="tm_qual_sppo8q9g6k"
      icon-height="12"
    />
  );
};

export default HsaPrequalify;
