"use client";

import React from "react";

const FrontrowWidget = () => {

  return (
    <div id="frontrow-widget">
      <script
        async
        type="text/javascript"
        src="https://app.thefrontrowhealth.com/api/widgets/script?presentation_type=quant&product_id=955"
      ></script>
      <iframe
        className="iframe-frontrow"
        id="iframe-frontrow-quant"
        frameBorder="0"
        src="https://app.thefrontrowhealth.com/api/widgets?presentation_type=quant&product_id=955"
      ></iframe>
    </div>
  );
};

export default FrontrowWidget;
