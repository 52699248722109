"use client"
import { useEffect } from "react";

export default function ProductReview({ product , slug}: any) {
  let reviewProductId: any;
  if (slug === "mark") {
    reviewProductId = 6878347133027;
  } else if (slug === "range") {
    reviewProductId = 6633840476259;
  } else if (slug === "orbit") {
    reviewProductId = 4167630651491;
  } else if (slug === "band") {
    reviewProductId = 6546274156643;
  } else if (slug === "book") {
    reviewProductId = 4167455015011;
  } else if (slug === "set") {
    reviewProductId = 7009601257571;
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://cdn1.stamped.io/files/widget.min.js";
    script.type = "text/javascript";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      // After the widget script is loaded, initialize it with productId
      window.StampedFn?.init({
        apiKey: 'pubkey-7Y2q27EZx658143R5FRdYjA1FiKK4I',
        sId: '150068',
      });
    };

    return () => {
      // Cleanup the script when the component unmounts
      document.head.removeChild(script);
    };
  }, [reviewProductId]);

  return (
    <>
      <div id="stamped-main-widget"
        data-product-id={reviewProductId}
        data-name={product?.video_banner_title}
        data-url={product?.url}
        data-image-url={product?.tutorial_desktop_image_ur}
        data-description={product?.video_banner_description}
        data-product-sku={product?.handle}>
      </div>
    </>
  );
}
