"use client";

import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { S3_BASE_URL } from "@/utils/constants";
import { CarouselImage, ProductContent } from "@/types/product-content";

interface CarouselProps {
  product: ProductContent;
}

export default function ProductCarousel({ product }: CarouselProps) {
  const sliderRef = useRef<Slider | null>(null);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [autoplay, setAutoplay] = useState(true);

  const brandSettings = {
    dots: false,
    arrows: false,
    autoplay: autoplay, // Control autoplay via scroll direction
    autoplaySpeed: 2000, // Speed of autoplay
    infinite: true,
    rtl: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 780,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 574,
        settings: {
          arrows: false,
          centerMode: false,
          slidesToShow: 2,
        },
      },
    ],
  };

  useEffect(() => {
    const handleScroll = () => {
      const newScrollY = window.scrollY;

      // Inverse scroll behavior
      if (newScrollY > lastScrollY) {
        // Scrolling down
        if (sliderRef.current) {
          sliderRef.current.slickPrev(); // Move left to right
        }
      } else {
        // Scrolling up
        if (sliderRef.current) {
          sliderRef.current.slickNext(); // Move right to left
        }
      }
      
      // Update last scroll position
      setLastScrollY(newScrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <div className="brand-section">
      <Slider ref={sliderRef} {...brandSettings}>
        {product?.carousel_images?.map((item: CarouselImage, index: number) => (
          <div className="brand-img-section d-flex justify-content-center" key={`brand-img-ind-${index}`}>
            <Image
              src={`${S3_BASE_URL}${item?.image_url}`}
              alt=""
              height={60}
              width={190}
              className="d-md-block d-none brands-img"
            />
            <Image
              src={`${S3_BASE_URL}${item?.image_url}`}
              alt=""
              height={50}
              width={100}
              className="d-block d-sm-none brands-img"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}
