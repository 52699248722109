"use client";

import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "next/image";
import { S3_BASE_URL } from "@/utils/constants";
import { CardSliderContent, ProductContent } from "@/types/product-content";
interface CardProps {
  product: ProductContent;
}

export default function ProductCardSection({ product }: CardProps) {
  const sliderRef = useRef<Slider>(null);
  const desktopSliderRef = useRef<Slider>(null);
  const goToPrev = () => {
   if (desktopSliderRef.current) {
    desktopSliderRef.current.slickPrev();
}
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  
  const goToNext = () => {

    if (desktopSliderRef.current) {
      desktopSliderRef.current.slickNext();
    }
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const [expandedIndices, setExpandedIndices] = useState<number[]>([]);

  const toggleExpanded = (index: number) => {
    setExpandedIndices((prevIndices) =>
      prevIndices.includes(index)
        ? prevIndices.filter((i) => i !== index)
        : [...prevIndices, index]
    );
  };

  const truncateText = (text: string, isExpanded: boolean) => {
    if (isExpanded) return text;

    const maxLength = 100;
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  const renderCardText = (text: string, index: number) => {
    const isExpanded = expandedIndices.includes(index);
    const truncatedText = truncateText(text, isExpanded);
    const shouldShowButton = text.length > 100;

    return (
      <>
        <div className="card-text">
          <div dangerouslySetInnerHTML={{ __html: truncatedText || "" }}></div>
          {shouldShowButton && (
            <button
              className="btn btn-link p-0"
              style={{ color: "#D41C2C", textDecoration: "none" }}
              onClick={() => toggleExpanded(index)}
            >
              {isExpanded ? "View Less" : "View More"}
            </button>
          )}
        </div>
      </>
    );
  };
  const [selectedSlide, setSelectedSlide] = useState(0);
  const handleAfterChange = (currentSlide: number) => {
    setSelectedSlide(currentSlide);
  };
  const desktopSliderSettings = {
    dots: false,
    arrows: false,  // Arrows will be handled by the SliderNavigation component
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const cardSliderSettings = {
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    afterChange: handleAfterChange,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          dots: true,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
  };

  return (
    <>
      <div className="restore-realign-wrapper text-center py-sm-5 py-0 my-5">
        <p className="text-white">{product?.first_card_title}</p>
      </div>

      <div className="card-section-layout d-md-block d-none">
        <div className="slider-holder container">
          <div className="row my-2">
            <div className="col-md-7 p-0 d-flex align-items-lg-start justify-content-lg-start justify-content-sm-center align-items-sm-center justify-content-center"></div>

            <div className="col-md-5 my-3  text-end d-flex align-items-lg-end justify-content-lg-end justify-content-sm-center align-items-sm-center justify-content-end px-5">
              <Image
                src="/assets/images/new-image/arrow/buttonLeft.png"
                alt="Previous"
                className="text-white fw-bold me-2 pointer img-arrow-right"
                width={36}
                height={36}
                onClick={goToPrev}
              />
              <Image
                src="/assets/images/new-image/arrow/buttonRight.png"
                alt="Next"
                className="text-white fw-bold pointer img-arrow-left"
                width={36}
                height={36}
                onClick={goToNext}
              />
            </div>
            <div className="row p-0 m-0">
              <Slider
                {...desktopSliderSettings}
                ref={desktopSliderRef}
                className="p-0 m-0 gap-3"
              >
                {product?.card_slider_contents?.map(
                  (item: CardSliderContent, index: number) => (
                    <div
                      className="d-flex justify-content-center card-section"
                      key={index}
                    >
                      <div className="card px-2 px-sm-0 border-0">
                        <div className="position-relative card-hover">
                          <Image
                            src={`${S3_BASE_URL}${item?.image_url}`}
                            alt="card-image"
                            className="card-img-top img-fluid d-none d-sm-block"
                            width={366}
                            height={515}
                          />
                          <Image
                            src={`${S3_BASE_URL}${item?.image_url}`}
                            alt="card-image"
                            className="card-img-top img-fluid d-block d-sm-none"
                            width={300}
                            height={400}
                          />
                          <div className="position-absolute left-0 bottom-0">
                            <h5 className="card-title m-4 text-white">
                              {item?.title}
                            </h5>
                          </div>
                        </div>
                        <div className="card-body">
                          <p className="mb-0 text-white">{item?.description}</p>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </Slider>
            </div>
            <div className="mark-button d-flex justify-content-center align-items-center"></div>
          </div>
        </div>
      </div>
      <div className="card-section-layout  d-md-none d-block">
        <div className="slider-holder container">
          <div className="row my-2">
            <div className="col-md-7 p-0 d-flex align-items-lg-start justify-content-lg-start justify-content-sm-center align-items-sm-center justify-content-center"></div>

            <div className="col-md-5 my-3  text-end d-flex align-items-lg-end justify-content-lg-end justify-content-sm-center align-items-sm-center justify-content-end px-5">
              <Image
                src="/assets/images/new-image/arrow/buttonLeft.png"
                alt="Previous"
                className="text-white fw-bold me-2 pointer img-arrow-right"
                width={36}
                height={36}
                onClick={goToPrev}
              />
              <Image
                src="/assets/images/new-image/arrow/buttonRight.png"
                alt="Next"
                className="text-white fw-bold pointer img-arrow-left"
                width={36}
                height={36}
                onClick={goToNext}
              />
            </div>
            <div className="row p-0 m-0">
              <Slider
                {...cardSliderSettings}
                ref={sliderRef}
                className="p-0 m-0 gap-3"
              >
                {product?.card_slider_contents?.map(
                  (item: CardSliderContent, index: number) => (
                    <div
                      className="d-flex justify-content-center card-section"
                      key={index}
                    >
                      <div
                        className={`card px-2 px-sm-0 border-0 ${
                          selectedSlide === index ? "active-card" : ""
                        }`}
                      >
                        <div
                          className={`position-relative card-hover ${
                            selectedSlide === index ? "active" : ""
                          }`}
                        >
                          <Image
                            src={`${S3_BASE_URL}${item?.image_url}`}
                            alt="card-image"
                            className="card-img-top img-fluid d-none d-sm-block"
                            width={366}
                            height={515}
                          />
                          <Image
                            src={`${S3_BASE_URL}${item?.image_url}`}
                            alt="card-image"
                            className="card-img-top img-fluid d-block d-sm-none"
                            width={300}
                            height={400}
                          />
                          <div className="position-absolute left-0 bottom-0">
                            <h5
                              className={`m-4 text-white card-title ${
                                selectedSlide === index ? "active" : ""
                              }`}
                            >
                              {item?.title}
                            </h5>
                          </div>
                        </div>
                        <div className="card-body">
                          <p className="mb-0 text-white">{item?.description}</p>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </Slider>
            </div>
            <div className="mark-button d-flex justify-content-center align-items-center"></div>
          </div>
        </div>
      </div>
    </>
  );
}
