'use client';

import React, { useState } from 'react';
import Image from 'next/image';
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure Bootstrap CSS is imported
import TutorialModal from './tutorialModal';
import { S3_BASE_URL } from '@/utils/constants';
import { AdditionalAccordion, ProductContent } from '@/types/product-content';
interface WatchVideoProps {
  product: ProductContent;
}

export default function WatchVideoSection({ product }: WatchVideoProps) {
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <TutorialModal
        show={showModal}
        handleClose={handleClose}
        product={product}
      />

      <div className='d-flex flex-column w-100'>
        {product?.tutorial_video_url && (
          <>
            <div className='watch-vedio-wrapper d-none d-sm-block position-relative order-2 order-sm-1'>
              <div className='position-absolute w-100 top-50 text-center'>
                <button
                  className='watch-vedio-btn text-center'
                  onClick={handleShow}
                >
                  Watch tutorial
                </button>
              </div>
              <Image
                src={`${S3_BASE_URL}${product?.tutorial_desktop_image_url}`}
                alt='Description of the image'
                width={1440}
                height={508}
                className='img-fluid watch-vedio-img'
              />
            </div>

            <div className='watch-vedio-wrapper d-block d-sm-none d-flex flex-column align-items-center justify-content-center order-2 order-sm-1'>
              <Image
                src={`${S3_BASE_URL}${product?.tutorial_mobile_image_url}`}
                alt='Description of the image'
                width={428}
                height={400}
                className='img-fluid ms-md-3 mt-3 mb-4'
              />
              <button className='watch-vedio-btn my-3' onClick={handleShow}>
                Watch tutorial
              </button>
            </div>
          </>
        )}
        {product.additional_accordion[0].title && (
          <div className='container watch-vedio-holder py-sm-5 pt-2 px-5 px-sm-0 order-1 order-sm-2'>
            <div className='faq-section d-flex'>
              <div className='w-100'>
                <div className='accordion bg-transparent' id='accordionExample'>
                  {product.additional_accordion?.map(
                    (item: AdditionalAccordion, index: number) => (
                      <div
                        key={index}
                        className='accordion-item bg-transparent border-0'
                      >
                        <h3 className='accordion-header border-top'>
                          <button
                            className={`accordion-button px-0 ${
                              activeIndex === index ? '' : 'collapsed'
                            } bg-transparent text-white`}
                            type='button'
                            onClick={() => toggleAccordion(index)}
                          >
                            {item?.title}
                          </button>
                        </h3>
                        <div
                          className={`accordion-collapse  ${
                            activeIndex === index ? 'show' : ''
                          }`}
                          // data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body bg-transparent text-white px-0'>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item?.description ?? '',
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
                {/* <div className="accordion bg-transparent" id="accordionExample">
            
                <div className="accordion-item bg-transparent border-0">
                  <h3 className="accordion-header border-top">
                    <button
                      className="accordion-button collapsed bg-transparent text-white faq-question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSpecifications"
                      aria-expanded="false"
                      aria-controls="collapseSpecifications"
                    >
                      Specifications
                    </button>
                  </h3>
                  <div
                    id="collapseSpecifications"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body bg-transparent text-white">
                      <div className='d-flex flex-row gap-3 gap-sm-5'>
                        <div className='d-flex flex-column'>
                          <p className='mb-2'>Measurements</p>
                          <ul>
                            <li className='text-white'>Weight: 9 oz</li>
                            <li className='text-white'>Width: 1.63 to 3.06 in</li>
                            <li className='text-white'>Length: 5.06 in</li>
                            <li className='text-white'>Height: 3.94 in</li>
                          </ul>
                        </div>
                        <div className='d-flex flex-column col'> 
                          <p className='mb-2'>Materials</p>
                          <ul>
                            <li className='text-white w-100'>Polycarbonate / acrylonitrile butadiene styrene (PC/ABS)</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

         
                <div className="accordion-item bg-transparent border-0">
                  <h3 className="accordion-header border-top">
                    <button
                      className="accordion-button collapsed bg-transparent text-white faq-question"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseResearch"
                      aria-expanded="false"
                      aria-controls="collapseResearch"
                    >
                      Research
                    </button>
                  </h3>
                  <div
                    id="collapseResearch"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body bg-transparent text-white">
                      <ul>
                        <li className='text-white'>84% of Aletha users report an improvement in their muscle tension*</li>
                        <li className='text-white'>85% of Aletha users report an improvement in their pain*</li>
                        <li className='text-white'>62% of Aletha users report an improvement in their flexibility*</li>
                        <li className='text-white'>39% of Aletha users report better sleep*</li>
                        <li className='text-white'>Backed by over 190 healthcare practitioners including physicians and physical therapists</li>
                      </ul>
                      <p className='my-3'>*based off self-report survey data of 377 Aletha Health customers</p>
                    </div>
                  </div>
                </div>
              </div> */}
                <hr className='text-white mt-0 custom-opacity' />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
