"use client";

import { ProductContent } from "@/types/product-content";
import React from "react";
interface VideoProps {
  product: ProductContent;
}


export default function ProductVideoSection({ product }: VideoProps) {
  return (
    <>
      <div className="w-75 mb-3 mx-auto youtube-video-holder d-none d-sm-block">
        <iframe
          width="100%"
          height="775"
          className="youtube-video "
          src={product?.product_video_url}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          title="Video"
        ></iframe>
      </div>
      <div className="w-100 my-2 mx-auto youtube-video-holder d-block d-sm-none">
        <iframe
          width="100%"
          height="215"
          className="youtube-video"
          src={product?.product_video_url}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          title="Video"
        ></iframe>
      </div>
    </>
  );
}
