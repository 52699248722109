

'use client';

import { ProductContent } from "@/types/product-content";

interface HolisticProps {
  product: ProductContent;
}
export default function ProductHolisticSection({product}:HolisticProps) {
  return (
    <div className="health-holder d-none d-sm-block">
        <div className="border border-white health-container">
            <p className="text-white text-center p-5 m-0">
           {product?.additional_description || ""}
            </p>
        </div>
    </div>
  );
}

